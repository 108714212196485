import React from "react";
import "./page.css";

const PrivacyPolicy = () => {
  return (
    <div>
      <div class="container text-left">
        <div class="privacy-header">
          <h1 class="heading1">MetaMindfull Privacy Policy</h1>
          <div class="date">Last Updated: July 31, 2024</div>
        </div>
        <div class="list-container">
          <ol>
            <li class="list-headings">
              Introduction.
              <ol class="list-content">
                <li>
                  Welcome to the MetaMindfull (Mindfulness AI Chatbot) available
                  on the Apple App Store. Your mental and emotional well-being
                  is our top priority, as is the safeguarding of your personal
                  data. This policy describes our data practices specifically
                  tailored for our mindfulness application available on the
                  Apple App Store.
                </li>
              </ol>
            </li>
            <li class="list-headings">
              Data Collection.
              <ol class="list-content">
                <li class="content-text">
                  We collect personal data only when necessary to provide and
                  improve our services. The types of personal data we collect
                  include your name, email address, and any other information
                  you choose to provide us with.
                </li>
                <li class="content-text">
                  The chatbot collects data only to improve your mindfulness
                  experience and does not gather personally identifiable
                  information unless explicitly provided by you.
                </li>
                <li class="content-text">
                  Chat sessions may be logged for enhancing the chatbot's
                  performance. Personal details are excluded unless shared by
                  the user.
                </li>
                <li class="content-text">
                  No additional data from your device (contacts, location,
                  camera) is accessed or collected without explicit permission.
                </li>
              </ol>
            </li>
            <li class="list-headings">
              Data Usage.
              <ol class="list-content">
                <li class="content-text">
                  Data is used to:
                  <ul>
                    <li class="ul-item">
                      Personalize and enhance mindfulness recommendations.
                    </li>
                    <li class="ul-item">
                      Analyze usage patterns to continuously improve the user
                      experience.
                    </li>
                    <li class="ul-item">Resolve technical issues.</li>
                  </ul>
                </li>
                <li class="content-text">
                  Your data is never used for advertising purposes or shared
                  with third-party services for marketing.
                </li>
              </ol>
            </li>
            <li class="list-headings">
              Data Sharing.
              <ol class="list-content">
                <li class="content-text">
                  We commit to not selling, renting, or sharing your personal
                  data with any third parties.
                </li>
                <li class="content-text">
                  Anonymized, aggregated data might be used for research to
                  improve mindfulness techniques, but without any identifying
                  information.
                </li>
              </ol>
            </li>
            <li class="list-headings">
              Data Storage and Retention.
              <ol class="list-content">
                <li class="content-text">
                  All interactions are stored using advanced security protocols.
                </li>
                <li class="content-text">
                  Chat logs are retained for a limited duration for analytical
                  purposes and then systematically deleted to maintain your
                  privacy.
                </li>
              </ol>
            </li>
            <li class="list-headings">
              Data Security.
              <ol class="list-content">
                <li class="content-text">
                  Rigorous security measures are employed to protect your data.
                </li>
                <li class="content-text">
                  Should there be a security breach, we pledge to notify the
                  affected users promptly and take all necessary remedial
                  actions.
                </li>
              </ol>
            </li>
            <li class="list-headings">
              User Rights.
              <ol class="list-content">
                <li class="content-text">
                  You have the right to inquire about your data and its
                  utilization.
                </li>
                <li class="content-text">
                  At any point, you can request the deletion of your data.
                </li>
              </ol>
            </li>
            <li class="list-headings">
              Apple App Store Provisions
              <ol class="list-content">
                <li class="content-text">
                  Our data practices comply with Apple's guidelines for data
                  collection and usage.
                </li>
                <li class="content-text">
                  We abide by Apple's tracking transparency requirements,
                  ensuring no data is used for unauthorized tracking purposes.
                </li>
              </ol>
            </li>
            <li class="list-headings">
              Changes to Privacy Policy.
              <ol class="list-content">
                <li class="content-text">
                  In case of updates to our Privacy Policy, they will be
                  reflected on this page. Significant changes will be
                  communicated to users via the app.
                </li>
              </ol>
            </li>
            <li class="list-headings">
              Contact.
              <ol class="list-content">
                <li class="content-text">
                  For any inquiries, questions or concerns, please
                  contact our us at: info@metamindful.ai
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
